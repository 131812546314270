import React, { useState } from "react"
import axios from "axios";
/*import { Link } from "gatsby"
import Layout from "../components/layout"*/
const MyForm = () => {

    const [setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/a6e4e67c-25c2-4343-a81f-cacd80787733",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Thanks!", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };
    const formStyle = {
        textAlign: 'left'
    }
    const buttonsStyle = {
        // margin: '1rem',
        textAlign: 'center',
        backgroundColor: '#6b7ddf',
        borderRadius: '2px',
        fontSize: '14px',
        fontWeight: '600',
        padding: '5px 33px',
        color: '#fff',
        cursor: 'pointer',
        border: 'none',
        transition: 'all .3s ease',
    }
    const input = {
        width: '100%',
        border: '1px solid #e1e4e8',
        padding: '15px',
        borderRadius: '2px',
        resize: 'none',
        height: '36px',
        transition: 'all .2s ease',
        margin: '10px 0'
    }

    return (
        <div className="contact_layout">
            <div className="col-md-8 mt-5">
                <form style={formStyle} onSubmit={handleOnSubmit}>
                    <input style={input} type="email" name="email" placeholder="Email" required/>
                    <input style={input} type="text" name="name" placeholder="Name" required/>
                    <input style={input} type="text" name="message" placeholder="Message" required/>
                    <button style={buttonsStyle} type="submit">Send</button>
                </form>
            </div>
        </div>
    );
};

export default MyForm;